<template>
  <div class="home-page">
    <iframe :src="statisticsUrl" frameborder="0" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    }
  },
  computed: {},
  created() {
    this.loadingFn()
  },
  methods: {
    loadingFn() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        loading.close()
      }, 500)
    }
  }
}
</script>

<style lang="scss">
.home-page {
  width: 1470px;
  height: 100%;
  margin: 0 auto;
  padding: 0 0 16px 0 !important;
  background: #F6F6F8;
}
</style>
